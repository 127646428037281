"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCollider = void 0;
const aframe_1 = require("aframe");
const rapier_system_1 = require("../systems/rapier-system");
const rapier3d_compat_1 = require("@dimforge/rapier3d-compat");
const bounds_1 = require("../utils/bounds");
const body_1 = require("./body");
const vector_1 = require("../utils/vector");
const { Matrix4, Vector3 } = aframe_1.THREE;
const async_component_1 = require("../async-component");
const promise_1 = require("../utils/promise");
const schema = {
    shape: { type: 'string', default: 'box' },
    wrap: { type: 'boolean', default: true },
    size: { type: 'vec3' },
    translation: { type: 'vec3' },
    density: { type: 'number', default: 1.0 },
    friction: { type: 'number', default: 1.0 },
    restitution: { type: 'number', default: 0 },
    restitutionCombineRule: { type: 'string', default: 'average' },
    sensor: { type: 'boolean' },
    collisionGroups: { type: 'number', default: 0xffffffff },
};
class Collider {
    constructor(el, attrName, collider, colliderDesc) {
        this.el = el;
        this.attrName = attrName;
        this.collider = collider;
        this.colliderDesc = colliderDesc;
    }
    static async initialize(el, data, attrName) {
        let [p, resolve, reject] = (0, promise_1.buildPromise)();
        let rapier = await (0, rapier_system_1.getRapier)();
        let body = await (0, body_1.getBody)(el);
        if (body === null) {
            throw new Error('Must attach "body" to attach "collider" component');
        }
        let buildCollider = () => {
            let object3D = el.getObject3D('mesh'); // TODO: This needs to be only if model
            let scale = el.getAttribute('scale');
            let colliderDesc = getColliderDesc(data, object3D, scale);
            let collider = rapier.generateCollider(colliderDesc, body);
            resolve(new Collider(el, attrName, collider, colliderDesc));
        };
        if (!data.wrap || el.getObject3D('mesh') !== undefined) {
            // Bypass if not wrap or model already loaded
            buildCollider();
        }
        else {
            el.addEventListener('model-loaded', buildCollider.bind(this));
        }
        return p;
    }
    wireframe() {
        let { geometry, line } = (0, bounds_1.getColliderObject)(this.colliderDesc);
        return line;
    }
    async update(data, oldData) {
        if (data.sensor !== oldData.sensor) {
            this.collider.setSensor(data.sensor);
            this.colliderDesc.setSensor(data.sensor);
        }
        if (data.collisionGroups !== oldData.collisionGroups) {
            this.collider.setCollisionGroups(data.collisionGroups);
        }
        // TODO: This is _weird_
        let rapier = await (0, rapier_system_1.getRapier)();
        if (rapier.debug) {
            let wireframe = this.wireframe();
            // Apply scale up, wonder if there's an easier way to handle this all....
            // Notes: we applied a scale-down for the physics system, but it's being _reapplied_ to this geometry
            // So we are sclaing this back up so it's the correct size when that scale is applied.
            let scale = this.el.getAttribute('scale');
            wireframe.applyMatrix4(new Matrix4().makeScale(1 / scale.x, 1 / scale.y, 1 / scale.z));
            this.el.setObject3D(this.attrName, wireframe);
        }
    }
    async remove() {
        let rapier = await (0, rapier_system_1.getRapier)();
        this.el.removeObject3D(this.attrName);
    }
}
exports.getCollider = (0, async_component_1.registerAsyncComponent)('collider', Collider.initialize, {
    schema,
    multiple: true,
    dependencies: ['body'],
});
function getColliderDesc(data, object3D, scale) {
    let size;
    let translation;
    if (data.wrap) {
        let boundingBox = (0, bounds_1.getBoundingBox)(object3D);
        // Notes: we're computing the local bounding box (by inverting the world transform), but
        // this leaves us with an issue: the local object is _huge_ and being scaled down when it's placed in the world.
        // So instead of saying this collider is the size of a skyscraper in the Physics system, we instead scale it down.
        // We don't need to move it or rotate it because the rigid body is moved and rotated, actually.
        boundingBox.applyMatrix4(new Matrix4().makeScale(scale.x, scale.y, scale.z));
        let center = new Vector3();
        boundingBox.getCenter(center);
        size = new Vector3();
        boundingBox.getSize(size);
        translation = center.sub(object3D.position);
    }
    else {
        size = (0, vector_1.toVector3)(data.size);
        let sizeLen = size.length();
        if (sizeLen === 0) {
            throw new Error('Collider: `size` required if `wrap` is not set.');
        }
        translation = (0, vector_1.toVector3)(data.translation);
    }
    // console.log({ shape: data.shape, size, translation });
    let collider;
    if (data.shape === 'box') {
        // console.log({ shape: 'box', size, center, translation });
        collider = rapier3d_compat_1.ColliderDesc.cuboid(size.x / 2, size.y / 2, size.z / 2);
    }
    else if (data.shape === 'ball') {
        // console.log({ shape: 'ball', size, center, l: size.length(), translation });
        // Why is this off by 50%?
        collider = rapier3d_compat_1.ColliderDesc.ball(size.length() / 3);
    }
    else {
        throw new Error(`Unknown shape: '${data.shape}'`);
    }
    return collider
        .setTranslation(translation.x, translation.y, translation.z)
        .setDensity(data.density)
        .setFriction(data.friction)
        .setRestitution(data.restitution)
        .setRestitutionCombineRule(getRestitutionCombineRule(data.restitutionCombineRule))
        .setActiveCollisionTypes(rapier3d_compat_1.ActiveCollisionTypes.DEFAULT | rapier3d_compat_1.ActiveCollisionTypes.KINEMATIC_STATIC)
        .setActiveEvents(rapier3d_compat_1.ActiveEvents.INTERSECTION_EVENTS)
        .setSensor(data.sensor);
}
function getRestitutionCombineRule(rule) {
    switch (rule) {
        case 'average':
            return rapier3d_compat_1.CoefficientCombineRule.Average;
        case 'min':
            return rapier3d_compat_1.CoefficientCombineRule.Min;
        case 'multiply':
            return rapier3d_compat_1.CoefficientCombineRule.Multiply;
        case 'max':
            return rapier3d_compat_1.CoefficientCombineRule.Max;
        default:
            throw new Error(`Unknown restitution combine rule: ${rule}`);
    }
}
