"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fixSchema = void 0;
function fixSchema(input, schema) {
    let res;
    if (input === true) {
        res = {};
    }
    else {
        res = input;
    }
    for (let [field, scheme] of Object.entries(schema)) {
        if (res[field] === undefined) {
            res[field] = scheme.default;
        }
    }
    return res;
}
exports.fixSchema = fixSchema;
