"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeFile = void 0;
async function writeFile(data) {
    // create a new handle
    const newHandle = await window.showSaveFilePicker();
    // create a FileSystemWritableFileStream to write to
    const writableStream = await newHandle.createWritable();
    // write our file
    await writableStream.write(data);
    // close the file and write the contents to disk.
    await writableStream.close();
}
exports.writeFile = writeFile;
