"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sub = exports.add = exports.mulScalar = exports.vecLen = exports.toRadArray = exports.toArray = exports.fromVector4 = exports.fromVector3 = exports.toVector3 = void 0;
const aframe_1 = require("aframe");
const { Vector3: ThreeVector3, MathUtils } = aframe_1.THREE;
function toVector3(vec) {
    return new ThreeVector3(vec.x, vec.y, vec.z);
}
exports.toVector3 = toVector3;
function fromVector3(vec) {
    return { x: vec.x, y: vec.y, z: vec.z };
}
exports.fromVector3 = fromVector3;
function fromVector4(vec) {
    return { x: vec.x, y: vec.y, z: vec.z, w: vec.w };
}
exports.fromVector4 = fromVector4;
function toArray(vec) {
    return [vec.x, vec.y, vec.z];
}
exports.toArray = toArray;
function toRadArray(vec) {
    return toArray(vec).map((d) => MathUtils.degToRad(d));
}
exports.toRadArray = toRadArray;
function vecLen(vec3) {
    return Math.sqrt(vec3.x * vec3.x + vec3.y * vec3.y + vec3.z * vec3.z);
}
exports.vecLen = vecLen;
function mulScalar(vec3, scale) {
    return { x: vec3.x * scale, y: vec3.y * scale, z: vec3.z * scale };
}
exports.mulScalar = mulScalar;
function add(a, b) {
    return { x: a.x + b.x, y: a.y + b.y, z: a.z + b.z };
}
exports.add = add;
function sub(a, b) {
    return { x: a.x - b.x, y: a.y - b.y, z: a.z - b.z };
}
exports.sub = sub;
