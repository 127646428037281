"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerAsyncComponent = void 0;
const aframe_1 = require("aframe");
const schema_1 = require("./utils/schema");
;
function registerAsyncComponent(name, initializer, def) {
    (0, aframe_1.registerComponent)(name, {
        ...def,
        init: async function () {
            let data = (0, schema_1.fixSchema)(this.data, def.schema);
            this.component = initializer(this.el, data, this.attrName);
        },
        pause: async function () {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.pause) === 'function') {
                component.pause();
            }
            if (typeof (def.pause) === 'function') {
                def.pause.call(component);
            }
        },
        play: async function () {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.play) === 'function') {
                component.play();
            }
            if (typeof (def.play) === 'function') {
                def.play.call(component);
            }
        },
        remove: async function () {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.remove) === 'function') {
                component.remove();
            }
            if (typeof (def.remove) === 'function') {
                def.remove.call(component);
            }
        },
        tick: async function (time, timeDelta) {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.tick) === 'function') {
                component.tick(time, timeDelta);
            }
            if (typeof (def.tick) === 'function') {
                def.tick.call(component, time, timeDelta);
            }
        },
        tock: async function (time, timeDelta, camera) {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.tock) === 'function') {
                component.tock(time, timeDelta, camera);
            }
            if (typeof (def.tock) === 'function') {
                def.tock.call(component, time, timeDelta, camera);
            }
        },
        update: async function (oldData) {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.update) === 'function') {
                component.update(this.data, oldData);
            }
            if (typeof (def.update) === 'function') {
                def.update.call(component, oldData);
            }
        },
        updateSchema: async function () {
            if (this.component === undefined) {
                return;
            }
            let component = await this.component;
            if (typeof (component.updateSchema) === 'function') {
                component.updateSchema();
            }
            if (typeof (def.updateSchema) === 'function') {
                def.updateSchema.call(component);
            }
        },
    });
    return async function getComponent(el, required = false) {
        let component = el.components[name];
        if (component === undefined) {
            return null;
        }
        return await component.component;
    };
}
exports.registerAsyncComponent = registerAsyncComponent;
