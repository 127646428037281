"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerAsyncSystem = void 0;
const aframe_1 = require("aframe");
const schema_1 = require("./utils/schema");
;
function registerAsyncSystem(name, initializer, def) {
    (0, aframe_1.registerSystem)(name, {
        ...def,
        init: async function () {
            let data = (0, schema_1.fixSchema)(this.data, def.schema);
            this.system = initializer(this.el, data);
        },
        pause: async function () {
            if (this.system === undefined) {
                return;
            }
            let component = await this.system;
            if (typeof (component.pause) === 'function') {
                component.pause();
            }
            if (typeof (def.pause) === 'function') {
                def.pause.call(component);
            }
        },
        play: async function () {
            if (this.system === undefined) {
                return;
            }
            let component = await this.system;
            if (typeof (component.play) === 'function') {
                component.play();
            }
            if (typeof (def.play) === 'function') {
                def.play.call(component);
            }
        },
        tick: async function (time, timeDelta) {
            if (this.system === undefined) {
                return;
            }
            let component = await this.system;
            if (typeof (component.tick) === 'function') {
                component.tick(time, timeDelta);
            }
            if (typeof (def.tick) === 'function') {
                def.tick.call(component, time, timeDelta);
            }
        },
    });
    return async function getSystem(required = false) {
        let scene = document.querySelector('a-scene');
        if (!scene) {
            throw new Error('Missing scene element');
        }
        let res = scene.systems[name];
        if (!res) {
            throw new Error(`Must initialize ${name} system on scene`);
        }
        return (await res).system;
    };
}
exports.registerAsyncSystem = registerAsyncSystem;
