"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const aframe_1 = require("aframe");
const schema_1 = require("../utils/schema");
const vector_1 = require("../utils/vector");
const body_1 = require("./body");
(0, aframe_1.registerComponent)('track', {
    schema: {
        body: { type: 'selector' },
        relative: { type: 'vec3' },
    },
    depedencies: ['body'],
    selfBody: null,
    trackedBody: null,
    init: async function () {
        let data = (0, schema_1.fixSchema)(this.data, this.schema);
        var el = this.el;
        this.selfBody = await (0, body_1.getBody)(this.el);
        if (this.selfBody === null) {
            throw new Error(`"body" component required for "track" component`);
        }
        this.trackedBody = await (0, body_1.getBody)(this.data.body);
        if (this.trackedBody === null) {
            throw new Error(`Cannot find body for ${this.data.body}`);
        }
    },
    tick() {
        if (this.selfBody && this.trackedBody) {
            this.selfBody.setNextPosition((0, vector_1.add)(this.trackedBody.position(), this.data.relative));
            this.selfBody.setNextRotation(this.trackedBody.rotation());
        }
    },
});
