"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tdebug = void 0;
const cooldown = new Map();
function tdebug(name, ...args) {
    let last = cooldown.get(name);
    let now = Date.now();
    if (last === undefined || now - last > 1000) {
        console.log(name, ...args);
        cooldown.set(name, now);
    }
}
exports.tdebug = tdebug;
