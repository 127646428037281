"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quaternionFromEuler = void 0;
const aframe_1 = require("aframe");
const super_three_1 = require("super-three");
const vector_1 = require("./vector");
const { Quaternion } = aframe_1.THREE;
function quaternionFromEuler(rotation) {
    const quaternion = new Quaternion();
    const euler = new super_three_1.Euler(...(0, vector_1.toRadArray)(rotation), 'XYZ');
    quaternion.setFromEuler(euler);
    return quaternion;
}
exports.quaternionFromEuler = quaternionFromEuler;
