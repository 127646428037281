"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBody = exports.Body = void 0;
const aframe_1 = require("aframe");
const rapier_system_1 = require("../systems/rapier-system");
const rapier3d_compat_1 = require("@dimforge/rapier3d-compat");
const vector_1 = require("../utils/vector");
const { Vector3 } = aframe_1.THREE;
const async_component_1 = require("../async-component");
const from_mesh_1 = require("./from-mesh");
const schema = {
    type: { type: 'string', default: 'dynamic' },
    follow: { type: 'boolean', default: true },
    linVel: { type: 'vec3' },
    angVel: { type: 'vec3' },
    linDamp: { type: 'number' },
    angDamp: { type: 'number' },
    gravityScale: { type: 'number', default: 1.0 },
    canSleep: { type: 'boolean', default: true },
    ccd: { type: 'boolean', default: false },
};
class Body {
    constructor(el, entityId, type, follow, rigidBody, rigidBodyDesc) {
        this.el = el;
        this.entityId = entityId;
        this.type = type;
        this.follow = follow;
        this.rigidBody = rigidBody;
        this.rigidBodyDesc = rigidBodyDesc;
    }
    static async initialize(el, data) {
        let rapier = await (0, rapier_system_1.getRapier)();
        let entityId = rapier.registerEntity(el);
        let { position, rotation } = await (0, from_mesh_1.waitForMeshMatrix)(el);
        console.log(el.id, "canSleep", data.canSleep);
        let bodyDesc = getBodyDesc(data)
            .setTranslation(position.x, position.y, position.z)
            .setRotation(rotation)
            .setLinvel(...(0, vector_1.toArray)(data.linVel))
            .setAngvel(data.angVel)
            .setLinearDamping(data.linDamp)
            .setAngularDamping(data.angDamp)
            .setGravityScale(data.gravityScale)
            .setCanSleep(data.canSleep)
            .setCcdEnabled(data.ccd);
        let body = rapier.generateRigidBody(bodyDesc, entityId);
        return new Body(el, entityId, data.type, data.follow, body, bodyDesc);
    }
    position() {
        return this.rigidBody.translation();
    }
    setPosition(position, wakeUp = true) {
        return this.rigidBody.setTranslation(position, wakeUp);
    }
    setRotation(rotation, wakeUp = true) {
        return this.rigidBody.setRotation(rotation, wakeUp);
    }
    setNextPosition(position) {
        this.nextPosition = position;
    }
    setNextRotation(rotation) {
        this.nextRotation = rotation;
    }
    setType(type) {
        this.type = type;
    }
    rotation() {
        return this.rigidBody.rotation();
    }
    isStatic() {
        return this.type === 'static';
    }
    isDynamic() {
        return this.type === 'dynamic';
    }
    isPositionBased() {
        return this.type === 'position';
    }
    applyImpulse(impulse, wakeUp = true) {
        this.rigidBody.applyImpulse(impulse, wakeUp);
    }
    async update(data, oldData) {
        // console.log([this.el.id, this.data.type, oldData.type]);
        if (data.type !== oldData.type) {
            this.setType(data.type);
        }
    }
    async remove() {
        let rapier = await (0, rapier_system_1.getRapier)();
        rapier.unregisterEntity(this.entityId);
    }
}
exports.Body = Body;
exports.getBody = (0, async_component_1.registerAsyncComponent)('body', Body.initialize, { schema, dependencies: [] });
function getBodyDesc(data) {
    return new rapier3d_compat_1.RigidBodyDesc(getBodyType(data.type));
}
function getBodyType(type) {
    switch (type) {
        case 'dynamic':
            return rapier3d_compat_1.RigidBodyType.Dynamic;
        case 'static':
            return rapier3d_compat_1.RigidBodyType.Static;
        case 'position':
            return rapier3d_compat_1.RigidBodyType.KinematicPositionBased;
        case 'velocity':
            return rapier3d_compat_1.RigidBodyType.KinematicVelocityBased;
        default:
            throw new Error(`Unknown rigid body type: ${type}`);
    }
}
