"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.waitForMeshMatrix = exports.getMeshMatrix = void 0;
const aframe_1 = require("aframe");
const body_1 = require("./body");
const schema_1 = require("../utils/schema");
const Vector = __importStar(require("../utils/vector"));
const promise_1 = require("../utils/promise");
const { Vector3, Quaternion } = aframe_1.THREE;
(0, aframe_1.registerComponent)('from-mesh', {
    schema: {},
    dependencies: ['body'],
    body: null,
    init: async function () {
        this.data = (0, schema_1.fixSchema)(this.data, this.schema);
        this.body = await (0, body_1.getBody)(this.el);
        if (this.body === null) {
            throw new Error('Cannot use "from mesh" without attached "body"');
        }
    },
    tick: function () {
        let { position, rotation, scale } = getMeshMatrix(this.el);
        this.body?.setNextPosition(position);
        this.body?.setNextRotation(rotation);
    },
});
function meshMatrix(mesh) {
    mesh.updateMatrixWorld();
    let position = new Vector3();
    let rotation = new Quaternion();
    let scale = new Vector3();
    mesh.matrixWorld.decompose(position, rotation, scale);
    return {
        position: Vector.fromVector3(position),
        rotation: Vector.fromVector4(rotation),
        scale: Vector.fromVector3(scale),
    };
}
function getMeshMatrix(el) {
    return meshMatrix(el.object3D);
}
exports.getMeshMatrix = getMeshMatrix;
async function waitForMeshMatrix(el) {
    let mesh = el.getObject3D('mesh');
    if (false) {
        return Promise.resolve(meshMatrix(mesh));
    }
    else {
        let [p, resolve, reject] = (0, promise_1.buildPromise)();
        setTimeout(() => {
            resolve(meshMatrix(el.getObject3D('mesh') ?? el.object3D));
        }, 1000);
        // el.addEventListener('loaded', function (evt: any) {
        //   console.log({evt});
        // });
        return p;
    }
}
exports.waitForMeshMatrix = waitForMeshMatrix;
