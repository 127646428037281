"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJoint = exports.Joint = void 0;
const aframe_1 = require("aframe");
const rapier_system_1 = require("../systems/rapier-system");
const rapier3d_compat_1 = require("@dimforge/rapier3d-compat");
const { Matrix4, Vector3 } = aframe_1.THREE;
const async_component_1 = require("../async-component");
const body_1 = require("./body");
const joint_1 = require("../utils/joint");
const schema = {
    type: { type: 'string', default: 'dynamic' },
    target: { type: 'selector' },
    anchor1: { type: 'vec3', default: { x: 0, y: 0, z: 0 } },
    anchor2: { type: 'vec3', default: { x: 0, y: 0, z: 0 } },
    frame1: { type: 'vec4', default: { x: 0, y: 0, z: 0, w: 1 } },
    frame2: { type: 'vec4', default: { x: 0, y: 0, z: 0, w: 1 } },
    axis: { type: 'vec3', default: { x: 0, y: 0, z: 0 } },
};
class Joint {
    constructor(el, attrName, target, thisBody, targetBody, joint, jointId, jointDesc) {
        this.el = el;
        this.attrName = attrName;
        this.target = target;
        this.thisBody = thisBody;
        this.targetBody = targetBody;
        this.joint = joint;
        this.jointId = jointId;
        this.jointDesc = jointDesc;
    }
    static async initialize(el, data, attrName) {
        console.log(data);
        let rapier = await (0, rapier_system_1.getRapier)();
        let thisBodyComp = await (0, body_1.getBody)(el);
        if (!thisBodyComp) {
            throw new Error(`Could not find body for element ${el}`);
        }
        let thisBody = thisBodyComp.rigidBody;
        let targetBodyComp = await (0, body_1.getBody)(data.target);
        if (!targetBodyComp) {
            throw new Error(`Could not find body for element ${data.target}`);
        }
        let targetBody = targetBodyComp.rigidBody;
        let jointDesc = getJointData(data);
        let jointId = rapier.world.impulseJoints.createJoint(rapier.world.bodies, jointDesc, thisBody.handle, targetBody.handle); // TODO: Handle other joint types
        let joint = rapier.world.impulseJoints.get(jointId); // TODO: Handle other joint types
        // let joint = rapier.world.createImpulseJoint(jointDesc, thisBody, targetBody); // TODO: Handle other joint types
        // console.log("el joint", joint);
        return new Joint(el, attrName, data.target, thisBody, targetBody, joint, jointId, jointDesc);
    }
    async update(data, oldData) {
        // TODO: This is _weird_
        let rapier = await (0, rapier_system_1.getRapier)();
        if (rapier.debug) {
            this.wireframe = this.getWireframe(); // TODO: This shouldn't be on all updates
            this.el.sceneEl.setObject3D(this.attrName, this.wireframe);
        }
    }
    async tick() {
        if (this.wireframe) {
            (0, joint_1.updateJointObject)(this.wireframe, this.jointDesc, this.el.getObject3D('mesh'), this.target.getObject3D('mesh'));
        }
    }
    async remove() {
        let rapier = await (0, rapier_system_1.getRapier)();
        this.el.sceneEl.removeObject3D(this.attrName);
        // console.log(this, this.joint)
        // rapier.world.removeImpulseJoint(this.joint);
        rapier.world.impulseJoints.remove(this.jointId, rapier.world.islands, rapier.world.bodies, true);
    }
    getWireframe() {
        return (0, joint_1.getJointObject)(this.jointDesc, this.el.getObject3D('mesh'), this.target.getObject3D('mesh'));
    }
}
exports.Joint = Joint;
exports.getJoint = (0, async_component_1.registerAsyncComponent)('joint', Joint.initialize, {
    schema,
    dependencies: ['body'],
});
function getJointData(data) {
    console.log('data', data);
    switch (data.type) {
        case 'fixed':
            return rapier3d_compat_1.JointData.fixed(data.anchor1, data.frame1, data.anchor2, data.frame2);
        case 'spherical':
            return rapier3d_compat_1.JointData.spherical(data.anchor1, data.anchor2);
        case 'revolute':
            return rapier3d_compat_1.JointData.revolute(data.anchor1, data.anchor2, data.axis);
        case 'prismatic':
            return rapier3d_compat_1.JointData.prismatic(data.anchor1, data.anchor2, data.axis);
        default:
            throw new Error(`Unknown joint type: ${data.type}`);
    }
}
