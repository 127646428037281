"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateJointObject = exports.getJointObject = void 0;
const aframe_1 = require("aframe");
const { BufferGeometry, Line, LineBasicMaterial, Vector3 } = aframe_1.THREE;
const vector_1 = require("./vector");
function getJointObject(desc, object1, object2) {
    const material = new LineBasicMaterial({
        color: 0xbb4400,
    });
    // TODO: Add support for frame/axis
    // switch (desc.jointType) {
    //   case JointData.fixed:
    // }
    const points = [];
    let pos1 = new Vector3();
    let pos2 = new Vector3();
    object1.updateMatrixWorld();
    object2.updateMatrixWorld();
    object1.getWorldPosition(pos1);
    object2.getWorldPosition(pos2);
    pos1.add((0, vector_1.toVector3)(desc.anchor1));
    pos2.add((0, vector_1.toVector3)(desc.anchor2));
    console.log("joint object", pos1, pos2);
    points.push(pos1);
    points.push(pos2);
    const geometry = new BufferGeometry().setFromPoints(points);
    return new Line(geometry, material);
}
exports.getJointObject = getJointObject;
function updateJointObject(line, desc, object1, object2) {
    let pos1 = new Vector3();
    let pos2 = new Vector3();
    object1.updateMatrixWorld();
    object2.updateMatrixWorld();
    object1.getWorldPosition(pos1);
    object2.getWorldPosition(pos2);
    pos1.add((0, vector_1.toVector3)(desc.anchor1));
    pos2.add((0, vector_1.toVector3)(desc.anchor2));
    //console.log("update", pos1, pos2);
    line.geometry.attributes.position.setXYZ(0, pos1.x, pos1.y, pos1.z);
    line.geometry.attributes.position.setXYZ(1, pos2.x, pos2.y, pos2.z);
    line.geometry.attributes.position.needsUpdate = true;
}
exports.updateJointObject = updateJointObject;
