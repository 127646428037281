"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPromise = void 0;
function buildPromise() {
    let resolve;
    let reject;
    let p = new Promise((resolve_, reject_) => {
        resolve = resolve_;
        reject = reject_;
    });
    return [p, resolve, reject];
}
exports.buildPromise = buildPromise;
