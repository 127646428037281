"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRapier = exports.Rapier = void 0;
const aframe_1 = require("aframe");
const rapier3d_compat_1 = require("@dimforge/rapier3d-compat");
const body_1 = require("../components/body");
const file_1 = require("../utils/file");
const async_system_1 = require("../async-system");
const { Matrix4, Vector3, Quaternion } = aframe_1.THREE;
;
class Rapier {
    constructor(world, debug, paused, autoSnap) {
        this.world = world;
        this.debug = debug;
        this.nextEntityId = 0;
        this.entities = new Map();
        this.paused = paused;
        this.autoSnap = autoSnap;
        this.bodyMap = new Map();
        this.colliderMap = new Map();
        this.attachKeyEventListeners();
    }
    static async initialize(el, data) {
        console.log('initializing physics system');
        await (0, rapier3d_compat_1.init)();
        console.log('physics system initialized');
        let gravity = { x: 0.0, y: -9.81, z: 0.0 }; // TODO: from data
        let world = new rapier3d_compat_1.World(gravity);
        return new Rapier(world, data.debug, data.paused, data.autoSnap);
    }
    registerEntity(entity) {
        let entityId = this.nextEntityId++;
        this.entities.set(entityId, entity);
        return entityId;
    }
    unregisterEntity(entityId) {
        this.entities.delete(entityId);
    }
    generateRigidBody(desc, entityId) {
        let rigidBody = this.world.createRigidBody(desc);
        this.bodyMap.set(rigidBody.handle, entityId);
        return rigidBody;
    }
    generateCollider(desc, body) {
        let collider = this.world.createCollider(desc, body.rigidBody.handle);
        this.colliderMap.set(collider.handle, body.rigidBody.handle);
        return collider;
    }
    autoSnapshot(timestamp) {
        if (this.autoSnap !== 0) {
            if (this.snapshotTimestamp === undefined ||
                timestamp - this.snapshotTimestamp > this.autoSnap) {
                this.snapshot = this.world.takeSnapshot();
                this.snapshotTimestamp = timestamp;
            }
        }
    }
    getEntityById(entityId) {
        return this.entities.get(entityId) ?? null;
    }
    getEntityByCollider(colliderHandle) {
        let bodyHandle = this.colliderMap.get(colliderHandle);
        if (bodyHandle === undefined) {
            return null;
        }
        let entityId = this.bodyMap.get(bodyHandle);
        if (entityId === undefined) {
            return null;
        }
        return this.getEntityById(entityId);
    }
    async step(timestamp, delta) {
        this.autoSnapshot(timestamp);
        if (!this.paused) {
            // this.world.timestep = delta / 1000;
            let eventQueue = new rapier3d_compat_1.EventQueue(true);
            try {
                this.world.step(eventQueue);
            }
            catch (e) {
                console.error('Physics Error: ', e);
                if (this.snapshotTimestamp) {
                    console.log(`Last auto snapshot saved ${Math.round(timestamp - this.snapshotTimestamp)}ms ago. Ctrl+V to save to disk`);
                }
                this.paused = true;
                throw e;
            }
            eventQueue.drainIntersectionEvents((colliderHandle1, colliderHandle2, intersecting) => {
                let entity1 = this.getEntityByCollider(colliderHandle1);
                let entity2 = this.getEntityByCollider(colliderHandle2);
                if (entity1 === null || entity2 === null) {
                    return;
                }
                let event = intersecting ? 'collide' : 'separate';
                entity1.dispatchEvent(new CustomEvent(event, {
                    detail: {
                        collidingEntity: entity2,
                        selfCollider: colliderHandle1,
                        otherCollider: colliderHandle2,
                    },
                }));
                entity2.dispatchEvent(new CustomEvent(event, {
                    detail: {
                        collidingEntity: entity1,
                        selfCollider: colliderHandle2,
                        otherCollider: colliderHandle1,
                    },
                }));
            });
            for (let [entityId, entity] of this.entities) {
                let body = await (0, body_1.getBody)(entity);
                if (body) {
                    let position;
                    let rotation;
                    if (body.isStatic()) {
                        // Skip
                    }
                    else if (body.isDynamic()) {
                        position = body.position();
                        rotation = body.rotation();
                    }
                    else if (body.isPositionBased()) {
                        position = body.nextPosition;
                        if (position !== undefined) {
                            body.setPosition(position);
                        }
                        rotation = body.nextRotation;
                        if (rotation !== undefined) {
                            body.setRotation(rotation);
                        }
                    }
                    else {
                        throw new Error(`Unknown body type: ${body.type}`);
                    }
                    if (body.follow) {
                        let parent = entity.object3D.parent;
                        let inverseParentPosition = new Vector3();
                        let inverseParentRotation = new Quaternion();
                        let inverseParentScale = new Vector3();
                        if (false) {
                            parent.updateMatrixWorld();
                            let inverseParent = new Matrix4();
                            inverseParent.copy(parent.matrixWorld);
                            inverseParent.invert();
                            inverseParent.decompose(inverseParentPosition, inverseParentRotation, inverseParentScale);
                        }
                        if (position) {
                            let positionVec = new Vector3(position.x, position.y, position.z);
                            positionVec.add(inverseParentPosition);
                            entity.object3D.position.set(positionVec.x, positionVec.y, positionVec.z);
                        }
                        if (rotation) {
                            let q = new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w);
                            q.multiply(inverseParentRotation);
                            entity.object3D.rotation.setFromQuaternion(q);
                        }
                    }
                }
            }
        }
    }
    pause() {
        this.paused = true;
    }
    play() {
        this.paused = false;
    }
    togglePaused() {
        this.paused = !this.paused;
    }
    attachKeyEventListeners() {
        window.addEventListener('keydown', this.onKeyPress.bind(this));
    }
    removeKeyEventListeners() {
        window.removeEventListener('keydown', this.onKeyPress);
    }
    async onKeyPress(event) {
        if (event.metaKey && String.fromCharCode(event.keyCode) === 'C') {
            event.preventDefault();
            event.stopPropagation();
            await this.captureSnapshot();
        }
        else if (event.metaKey && String.fromCharCode(event.keyCode) === 'V') {
            event.preventDefault();
            event.stopPropagation();
            if (this.snapshot) {
                await this.saveSnapshot();
            }
        }
        else if (event.metaKey && String.fromCharCode(event.keyCode) === 'S') {
            event.preventDefault();
            event.stopPropagation();
            await this.captureSnapshot();
            await this.saveSnapshot();
        }
        else if (!event.metaKey && String.fromCharCode(event.keyCode) === 'P') {
            event.preventDefault();
            event.stopPropagation();
            await this.togglePaused();
        }
    }
    async captureSnapshot() {
        this.snapshot = this.world.takeSnapshot();
        console.log('Snapshot', this.snapshot);
        console.log('Physics snapshot saved. Meta+V to save to disk');
    }
    async saveSnapshot() {
        let snapshot = this.snapshot;
        if (!snapshot) {
            throw new Error('Cannot save snapshot as none has been taken');
        }
        await (0, file_1.writeFile)(snapshot);
    }
    remove() {
        this.removeKeyEventListeners();
    }
    tick(timestamp, delta) {
        this.step(timestamp, delta);
    }
}
exports.Rapier = Rapier;
exports.getRapier = (0, async_system_1.registerAsyncSystem)('rapier-physics', Rapier.initialize, {
    schema: {
        debug: { type: 'boolean' },
        paused: { type: 'boolean' },
        autoSnap: { type: 'number', default: 0 },
    },
});
